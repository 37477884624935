import service from "../service.js";

//查询班组安全工器具试验信息
export const searchToolTest = params => {
    return service({
        method: 'POST',
        url: '/teamTest/searchToolTest',
        params
    })
}

export const addTeamToolTest = (data) => {
    return service({
        method: 'POST',
        url: '/teamTest/addTeamToolTest',
        data
    })
}

export const updateTeamToolTest = (data) => {
    return service({
        method: 'POST',
        url: '/teamTest/updateTeamToolTest',
        data
    })
}

export const delTeamToolTest = (id) => {
    return service({
        method: 'GET',
        url: '/teamTest/delTeamToolTest',
        params: {
            id
        }
    })
}

export const queryTeamToolTest = (id) => {
    return service({
        method: 'GET',
        url: '/teamTest/queryTeamToolTest',
        params: {
            id
        }
    })
}

export const getShTypes = () =>{
    return service({
        method: 'GET',
        url: '/param/getShTypes'
    })
}





